import axios from 'axios'
import { getToken } from '@/tool/common'
import { Modal } from 'ant-design-vue'
import store from '@/store'

const service = axios.create({
    baseURL: BaseConfig.Api,
    timeout: 15000,
    headers: {"Content-Type": "application/json;charset=UTF-8"},
    transformRequest: [
        function(data,headers) {
            var token = getToken()
            if (token) headers['Authorization'] = token
            return data
        }
    ],
    withCredentials: true, //允许跨域请求
})


service.interceptors.request.use(function (config) {
    config.data = JSON.stringify(config.data)
    return config;
}, function (error) {
    return Promise.reject(error);
});


// 添加响应拦截器
service.interceptors.response.use(function (response) {
    const result = response.data
    if(result.code == 50001 || result.code == 50002) {
        Modal.error({
            content: result.message,
            onOk(){
                // 执行登出 事件
                store.dispatch("Logout").then((result) => {
                    location.href = '/users';
                    return
                })
            }
        })
        return
    }
    return result;
}, function (error) {
    if(error.message == "Network Error") {
        Modal.error({
            title: '提示',
            content: "网络连接错误",
        })
        return
    }
    if(error.response.status == 404) {
        Modal.error({
            title: '提示',
            content: "接口不存在",
        })
        return
    }
    if(error.response.status == 500) {
        Modal.error({
            title: '服务器错误',
            content: error.response.data.message,
        })
        return
    }
    return Promise.reject(error);
});

export default service